const SECTIONS = {
	COMPANY: 1,
	MODEM: 2,
	POINT_SALE: 3,
	USER: 4,
	ROLE: 5
};

const USER_TYPES = {
	INTERNAL: 1,
	CLIENT: 2
};

const userTypesList = [
	{ id: USER_TYPES.INTERNAL, name: 'Внутренний' },
	{ id: USER_TYPES.CLIENT, name: 'Внешний' }
];

const initialRulesFormData = [
	{ruleType: SECTIONS.COMPANY, create:false, update:false, delete:false, view:false },
	{ruleType: SECTIONS.MODEM, /*create:false,*/ update:false, /*delete:false,*/ view:false },
	{ruleType: SECTIONS.POINT_SALE, create:false, update:false, delete:false, view:false },
	{ruleType: SECTIONS.USER, create:false, update:false, delete:false, view:false },
	{ruleType: SECTIONS.ROLE, create:false, update:false, delete:false, view:false }
]

const ruleTypes = [
	{ ruleType: SECTIONS.COMPANY, 		name: 'Company' },
	{ ruleType: SECTIONS.MODEM, 			name: 'Modem' },
	{ ruleType: SECTIONS.POINT_SALE, 	name: 'Point sale' },
	{ ruleType: SECTIONS.USER, 				name: 'User' },
	{ ruleType: SECTIONS.ROLE, 				name: 'Role' }
]

const googleMapsLibraries = ['places'];
const googleMapsApiKey = 'AIzaSyBg2q97Hn8Uv8ctUuNp2xbmFjMfhPzNmX0';

const weekDays = {
	1: 'Пн',
	2: 'Вт',
	3: 'Ср',
	4: 'Чт',
	5: 'Пт',
	6: 'Сб',
	7: 'Вс'
}

export {
	SECTIONS,
	USER_TYPES,
	userTypesList,
	initialRulesFormData,
	ruleTypes,
	googleMapsLibraries,
	googleMapsApiKey,
	// datePickerLocale,
	weekDays
}
