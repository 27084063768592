import { 
	NAVBAR_SET_META,
	NAVBAR_SET_ITEMS
} from '../../constants';

const initialState = {
	meta: {
		showCompanySelect: false
	},
	data: {
		selectedCompany: null,
	}
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	
	switch (type) {

		case NAVBAR_SET_META: {
			return { ...state, meta: { ...payload } }
		}

		case NAVBAR_SET_ITEMS: {
			let data = {...state.data};
			data[payload.prop] = payload.value;
			// console.log(prop)
			return { ...state, data }
		}

		default:
			return state;
	}
}