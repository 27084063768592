import { combineReducers } from 'redux';
import itemsDataReducerFor from './common/itemsDataReducerFor';
import filtersReducerFor from './common/filtersReducerFor';
import statusReducerFor from './common/statusReducerFor';

const modemsReducers = combineReducers({
  modemsData: itemsDataReducerFor('MODEMS_'),
  modemsFilters: filtersReducerFor('MODEMS_'),  
  modemsStatus: statusReducerFor('MODEMS_'),
});

export default modemsReducers;
