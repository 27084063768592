import { combineReducers } from 'redux';
import itemsDataReducerFor from './common/itemsDataReducerFor';
import statusReducerFor from './common/statusReducerFor';

const advertisingServicesReducers = combineReducers({
  advertisingServicesData: itemsDataReducerFor('ADVERTISING_SERVICES_'),
  advertisingServicesStatus: statusReducerFor('ADVERTISING_SERVICES_'),
});

export default advertisingServicesReducers;
