// import { fetchItemsFor } from './common/apiActions';
import { setMetaFor, setItemsFor } from './common/itemsDataActions';

const setNavbarMeta = meta => {
	return setMetaFor('NAVBAR_')(meta);
};

const setNavbarData = data => {
	return setItemsFor('NAVBAR_')(data);
};

export {
	setNavbarMeta,
	setNavbarData
}
