import async from '../components/Async';

// Landing
// import HomePage from '../pages/home/HomePage';

// Auth
import SignIn from '../pages/auth/SignIn';
// import SignUp from '../pages/auth/SignUp';
import ErrorPage from '../pages/auth/ErrorPage';
import { SECTIONS } from 'constants/global'

// import ResetPassword from "../pages/auth/ResetPassword";

// Layouts
// import SidebarCollapsed from "../pages/layouts/SidebarCollapsed";

// Pages
// import Profile from '../pages/pages/Profile';

// Dashboards
const DashboardPage = async(() => import('../pages/dashboards/DashboardPage/DashboardPage'));
const DataloadPage = async(() => import('../pages/dashboards/Dataload/DataloadPage'));
const Modems = async(() => import('../pages/dashboards/Modems/Modems'));
const Companies = async(() => import('../pages/dashboards/Companies/Companies'));
const Users = async(() => import('../pages/dashboards/Users/Users'));
const Roles = async(() => import('../pages/dashboards/Roles/Roles'));

// Routes
/*const homeRoutes = {
	path: '/',
	name: 'Home Page',
	component: HomePage,
	// redirect: '/app/users',
	children: null
};*/
const dashboardRoute = {
	path: '/dashboard',
	name: 'Дэшборд',
	component: DashboardPage,
	icon: 'icon-dashboard',
	// redirect: '/app/users',
	children: null,
	meta: {showWithoutChildren: true}
};

const dataLoadRoute = {
	path: '/dataload',
	name: 'Загрузка данных',
	component: DataloadPage,
	icon: 'icon-upload',
	children: null,
	meta: {showWithoutChildren: true}
};

const adminRoutes = {
	path: '/admin',
	name: 'Админ панель',
	// header: 'Main',
	icon: 'icon-edit2',
	containsHome: true,
	redirect: '/admin/modems',
	children: [
		/*{
			path: '/admin/dashboard',
			name: 'Стартовая',
			component: Default
			// badgeColor: "primary",
			// badgeText: "bage text"
		},*/
		{
			path: '/admin/modems',
			name: 'Модемы',
			component: Modems,
			icon: 'icon-signal',
			meta: {	ruleType: SECTIONS.MODEM }
		},
		{
			path: '/admin/companies',
			name: 'Компании',
			component: Companies,
			icon: 'icon-case',
			meta: {	ruleType: SECTIONS.COMPANY }
		},
		{
			path: '/admin/users',
			name: 'Пользователи',
			icon: 'icon-user',
			component: Users,
			meta: {	ruleType: SECTIONS.USER	}
		},
		{
			path: '/admin/roles',
			name: 'Настройка прав',
			icon: 'icon-settings',
			component: Roles,
			meta: {	ruleType: SECTIONS.ROLE	}
		}
	]
};

/*const pageRoutes = {
	path: '/pages',
	name: 'Pages',
	icon: LayoutIcon,
	children: [
		{
			path: '/pages/profile',
			name: 'Profile',
			component: Profile
		}
	]
};*/

const authRoutes = {
	path: '/auth',
	name: 'Auth',
	// icon: UsersIcon,
	badgeColor: 'secondary',
	badgeText: '12/24',
	children: [
		{
			path: '/auth/sign-in',
			name: 'Sign In',
			component: SignIn
		}
		/*{
			path: '/auth/sign-up',
			name: 'Sign Up',
			component: SignUp
		}*/
	]
};

const errorRoutes = {
	path: '*',
	name: 'NotFound',
	component: ErrorPage,
	error: {
		title: 'Page not found.',
		message: 'The page you are looking for might have been removed.',
		status: '404'
	}
};

// Dashboard specific routes
export const admin = [
	adminRoutes,
	// pageRoutes
	// layoutRoutes,
];

// Landing specific routes
// export const home = [homeRoutes];
export const dashboard = [dashboardRoute];
export const dataload = [dataLoadRoute];

// Auth specific routes
export const page = [authRoutes];

export const error = [errorRoutes];

// All routes
export default [
	dashboardRoute,
	dataLoadRoute,
	adminRoutes
	// pageRoutes,
	// errorRoutes
	// authRoutes,
	// layoutRoutes
];
