import React from 'react';
import PropTypes from 'prop-types';

// ======================
const CustomOption = ({ innerProps, isDisabled, data, isSelected }) => {
	return !isDisabled && (
		<div  className={`custom-option-container ${isSelected ? 'selected' : ''}`}>
			<div {...innerProps} className="react-select__option">
				<span>{ data.label }</span>
			</div>
		</div>
	);
};

CustomOption.propTypes = {
	data: PropTypes.object.isRequired,
	isDisabled: PropTypes.bool.isRequired
};

const SelectArrow = ({ innerProps, isFocused }) => {
	return  (
		<div className="select-arrow triangle on-x bottom"></div>
	);
};

const ClearIndicator = ({ innerProps, clearValue }) => {	
	return  (
		<div className="select-clear-button"
			onClick={clearValue}><i className="icomoon icon-close"/></div>
	);
};

/*const MultiValueLabel = (props) => {
	console.log(props)
	return  (
		<div className="select-clear-button"
			// onClick={clearValue}
		><i className=""/>asdasd</div>
	);
};*/

/*const MultiValueRemove = ({ selectProps, data }) => {
	// { innerProps, selectProps, clearValue }
	// console.log(props)
	const onClick = () => {
		// console.log( props.data)
		selectProps.value = selectProps.value.filter((val) => {
			return val !== data.value
		})
	}

	return  (
		<div className="select-clear-button multi"
			// onClick={onClick}
			><i className="icomoon icon-close"/></div>
	);
};*/

export {
	CustomOption,
	SelectArrow,
	ClearIndicator,
	// MultiValueRemove,
	// MultiValueLabel
};