import { fetchItemsFor, saveItemFor, deleteItemFor, commonActionFor } from './common/apiActions';
import { setItemsFor, setMetaFor, setFiltersFor, setSortingDataFor } from './common/itemsDataActions';

const fetchSegments = payload => {
	return fetchItemsFor('SEGMENTS_', '/segments')(payload);
};

const saveSegment = payload => {
	return saveItemFor('SEGMENTS_', '/segments')(payload);
};

const deleteSegment = id => {
	return deleteItemFor('SEGMENTS_', '/segments')(id);	
}

const setSegments = segments => {
	return setItemsFor('SEGMENTS_')(segments);
};

const setSegmentsFilters = filters => {
	return setFiltersFor('SEGMENTS_')(filters);
};

const setSegmentsMeta = meta => {
	return setMetaFor('SEGMENTS_')(meta);
};

const setSegmentsSorting = data => {
	return setSortingDataFor('SEGMENTS_')(data);
};

const reloadSegment = segmentId => {
	const options = {
		prefix: 'SEGMENTS_',
		payloadUrl: `/segments/reload/${segmentId}`,
		payloadMethod: 'PUT',
		message: 'Сегмент обновлен'
	}
	// console.log(options)
	return commonActionFor('SEGMENTS_')(options);
};

const fetchSegmentStatistics = ({segmentId, getParams}) => {
	const options = {
		prefix: 'SEGMENTS_',
		payloadUrl: `/segments/${segmentId}/history`,
		payloadMethod: 'GET',
		settings: {messageDisabled: true, silent:true},
		payload: {getParams: getParams}
	}
	// console.log(options)
	return commonActionFor('SEGMENTS_')(options);
};

export {
	fetchSegments,
	saveSegment,
	deleteSegment,
	setSegments,
	setSegmentsFilters,
	setSegmentsMeta,
	setSegmentsSorting,
	reloadSegment,
	fetchSegmentStatistics
}
