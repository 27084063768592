import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  // getIdsForSelect,
  setupSelectOptions,
} from "helpers"
// import {push as routerPush} from 'react-router-redux';
import { USER_TYPES } from "constants/global"

// import { toastr } from 'react-redux-toastr';

import {
  // Row,
  // Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // ListGroup,
  // ListGroupItem,
  Form,
  Label,
  // Input,
  Spinner,
} from "reactstrap"

import Select from "react-select"

import { signOut } from "redux/actions/authActions"
import { toggleSidebar } from "../redux/actions/sidebarActions"
import { fetchCompanies } from "redux/actions/companiesActions"
import { setNavbarData } from "redux/actions/navbarActions"
import { setSegmentsFilters } from "redux/actions/segmentsActions"

import { CustomOption, SelectArrow, ClearIndicator } from "components/CustomSelectParts"

// ===========================
const NavbarComponent = () => {
  const dispatch = useDispatch()

  const { showCompanySelect } = useSelector(state => state.navbar.navbarData.meta)
  const { segmentsFilters } = useSelector(state => state.segments)
  const { selectedCompany } = useSelector(state => state.navbar.navbarData.data)

  // const [selectedCompany, setSelectedCompany] = useState(null);
  const [companiesSelectOptionsList, setCompaniesSelectOptionsList] = useState([])
  // const companiesState = useSelector(state => state.companies.companiesData);
  // const companiesList = companiesState.itemsList;

  const { isAuthenticated, authUser } = useSelector(state => state.auth.authData)
  const { itemsLoading } = useSelector(state => state.auth.authStatus)
  const authLoading = itemsLoading

  const [isInitialMount, setInitialMount] = useState(true)

  // -------------------------

  const fetchCompaniesItems = () => {
    let params = { getParams: { max: -1 } }
    // console.log('ok')
    dispatch(fetchCompanies(params))
      .then(data => {
        setCompaniesSelectOptionsList(setupSelectOptions(data.data, "name"))
        // console.log(authUser)
      })
      .catch(() => {
        setCompaniesSelectOptionsList([])
      })
  }

  const handleCompaniesFieldChange = data => {
    // setSelectedCompany(data);
    dispatch(setSegmentsFilters({ ...segmentsFilters, page: 1 }))
    dispatch(setNavbarData({ prop: "selectedCompany", value: data }))
  }

  const handleLogout = () => {
    // console.log('ok')
    handleCompaniesFieldChange(null)
    dispatch(signOut())
  }

  const handleToggleSidebar = () => dispatch(toggleSidebar())

  // ===== Watch =======
  useEffect(() => {
    if (isInitialMount) {
      // ------ Component Mount -------
      // console.log(authUser)
      setInitialMount(false)
      // -----------------------------
    } else {
      // ------ Component Update -----
      // console.log('navbar: ', showCompanySelect)
      if (showCompanySelect) {
        // if (authUser.type === USER_TYPES.INTERNAL) {
        fetchCompaniesItems()
        // }

        if (authUser.company) {
          const selectedCompany = {
            value: authUser.company_id,
            label: authUser.company.name,
          }
          handleCompaniesFieldChange(selectedCompany)
        }
      }
    }
  }, [showCompanySelect])

  return (
    <Navbar color="white" light expand>
      <span className="sidebar-toggle d-flex mr-2" onClick={handleToggleSidebar}>
        <i className="hamburger align-self-center" />
      </span>

      <Form inline className="ml-3">
        {/*<Input
					type="text"
					placeholder="Search projects..."
					aria-label="Search"
					className="form-control-no-border mr-sm-2"
				/>*/}

        {showCompanySelect && (
          <div className="navbar-form-item d-flex">
            <Label className="mr-3 d-none d-md-flex">Компания</Label>
            {authUser.type === USER_TYPES.INTERNAL ? (
              <Select
                components={{
                  Option: CustomOption,
                  DropdownIndicator: SelectArrow,
                  ClearIndicator: ClearIndicator,
                }}
                isDisabled={authUser.type !== USER_TYPES.INTERNAL}
                // closeMenuOnSelect={false}
                hideSelectedOptions={true}
                isClearable={true}
                name="companiesIds"
                className={`react-select-container
									${selectedCompany ? "" : "placeholderColor"}`}
                classNamePrefix="react-select"
                options={companiesSelectOptionsList}
                placeholder="компания"
                value={selectedCompany}
                onChange={data => handleCompaniesFieldChange(data)}
              />
            ) : (
              <div className="">
                <b>{selectedCompany ? selectedCompany.label : "не определена"}</b>
              </div>
            )}
          </div>
        )}
      </Form>

      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          {/* <NavbarDropdown
						header="New Messages"
						footer="Show all messages"
						icon={MessageCircle}
						count={messages.length}
						showBadge>
						{messages.map((item, key) => {
							return (
								<NavbarDropdownItem
									key={key}
									icon={
										<img
											className="avatar img-fluid rounded-circle"
											src={item.avatar}
											alt={item.fullName
										/>
									}
									title={item.name}
									description={item.description}
									time={item.time}
									spacing
								/>
							);
						})}
					</NavbarDropdown> */}

          {/* <NavbarDropdown
						header="New Notifications"
						footer="Show all notifications"
						icon={BellOff}
						count={notifications.length}>
						{notifications.map((item, key) => {
							let icon = <Bell size={18} className="text-warning" />;

							if (item.type === "important") {
								icon = <AlertCircle size={18} className="text-danger" />;
							}

							if (item.type === "login") {
								icon = <Home size={18} className="text-primary" />;
							}

							if (item.type === "request") {
								icon = <UserPlus size={18} className="text-success" />;
							}

							return (
								<NavbarDropdownItem
									key={key}
									icon={icon}
									title={item.title}
									description={item.description}
									time={item.time}
								/>
							);
						})}
					</NavbarDropdown> */}

          {/* <UncontrolledDropdown nav inNavbar className="mr-2">
						<DropdownToggle nav caret className="nav-flag">
							<img src={usFlag} alt="English" />
						</DropdownToggle>
						<DropdownMenu right>
							<DropdownItem>
								<img
									src={usFlag}
									alt="English"
									width="20"
									className="align-middle mr-1"
								/>
								<span className="align-middle">English</span>
							</DropdownItem>
							<DropdownItem>
								<img
									src={esFlag}
									alt="Spanish"
									width="20"
									className="align-middle mr-1"
								/>
								<span className="align-middle">Spanish</span>
							</DropdownItem>
							<DropdownItem>
								<img
									src={deFlag}
									alt="German"
									width="20"
									className="align-middle mr-1"
								/>
								<span className="align-middle">German</span>
							</DropdownItem>
							<DropdownItem>
								<img
									src={nlFlag}
									alt="Dutch"
									width="20"
									className="align-middle mr-1"
								/>
								<span className="align-middle">Dutch</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown> */}

          {isAuthenticated && (
            <UncontrolledDropdown nav inNavbar>
              {/*<span className="d-none d-sm-inline-block">*/}

              <span className="">
                <DropdownToggle nav>
                  <img src={authUser.avatar} className="avatar img-fluid rounded-circle " alt={authUser.first_name} />
                  <span className="text-dark ml-3 d-none d-md-inline-block">{authUser.first_name}</span>
                  <span className="nav-caret triangle on-x bottom d-none d-md-inline-block"></span>
                </DropdownToggle>
              </span>
              <DropdownMenu right>
                {/*<DropdownItem>
									<User size={18} className="align-middle mr-2" />
									Profile
								</DropdownItem>*/}
                {/*<DropdownItem divider />*/}
                <DropdownItem>
                  {authLoading ? (
                    <Spinner size="sm" color="#fff" />
                  ) : (
                    <div onClick={handleLogout}>
                      <i className="icomoon icon-exit lg mr-2 align-middle" />
                      <span className="align-middle">Выход</span>
                    </div>
                  )}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default NavbarComponent

/* export default connect(store => ({
	app: store.app
}))(NavbarComponent);
*/
