import { 
	STATISTICS_SET_ITEMS,
} from '../../constants';

const initialState = {
};

const statisticsDataReducerFor = prefix => {
  const statisticsDataReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case prefix + STATISTICS_SET_ITEMS:
        return payload;

      default:
        return state;
    }
  };
  return statisticsDataReducer;
};

export default statisticsDataReducerFor;

/*export default (state = initialState, action) => {
	switch (action.type) {

		case STATISTICS_SET_ITEMS: {
			return action.payload
		}

		default:
			return state;
	}
}*/