import { combineReducers } from 'redux';
import navbarDataReducer from './navbar/navbarDataReducer';
// import filtersReducerFor from './common/filtersReducerFor';
// import statusReducerFor from './common/statusReducerFor';

const navbarReducers = combineReducers({
  navbarData: navbarDataReducer
});

export default navbarReducers;
