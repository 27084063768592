import { 
	STATISTICS_SET_FILTERS,
} from '../../constants';

import { extendMoment } from 'moment-range';
import Moment from 'moment';

const moment = extendMoment(Moment);
const today = moment().startOf('day');
const lastWeek = moment.range(today.clone().subtract(7, "days"), today)
const lastlastWeek = moment.range(
	today.clone().subtract(14, "days"), today.clone().subtract(7, "days")
)

const initialState = {
	isPeriods: false,
	dateRange1: lastWeek,
	dateRange2: lastlastWeek,
	selectedCities: [],
	selectedPointSales: [],
	selectedVendors: [],
	// selectedModems: [{value: 117, label: '117'}, {value: 118, label: '118'}]
	selectedModems: []
};

const statisticsFiltersReducerFor = prefix => {
  const statisticsFiltersReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case prefix + STATISTICS_SET_FILTERS:
        return { ...state, ...payload };

      default:
        return state;
    }
  };
  return statisticsFiltersReducer;
};

export default statisticsFiltersReducerFor;