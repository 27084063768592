import { SET_FILTERS } from '../../constants';

// console.log('red')
const commonFilters = {
	max: {value: 10, label: '10'},
	page: 1
};

const initialFiltersState = {
	MODEMS_: { 
		...commonFilters,
		selectedCompany: null,
		selectedCity: null,
		selectedAddress: null,
		selectedModelModems: null,
		// companyId: '',
		// cityId: '',
		// addressId: '',
		// modelModemsId: ''
	},
	COMPANIES_: { ...commonFilters },
	USERS_: {
		...commonFilters,
		type: '',
		isActive: null
	},
	ROLES_: { ...commonFilters },
	SEGMENTS_: { ...commonFilters }
};

const filtersReducerFor = prefix => {
	const filtersReducer = (state = initialFiltersState[prefix], action) => {
		const { type, payload } = action;
		switch (type) {
			case prefix + SET_FILTERS:
				return { ...state, ...payload };

			default:
				return state;
		}
	};
	return filtersReducer;
};

export default filtersReducerFor;
