import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"

import { reducer as toastrReducer } from "react-redux-toastr"
import sidebar from "./sidebarReducers"
import auth from "./authReducers"

import modemsReducers from "./modemsReducers"
import companiesReducers from "./companiesReducers"
import usersReducers from "./usersReducers"
import rolesReducers from "./rolesReducers"
import advertisingServicesReducers from "./advertisingServicesReducers"
import positionInstallModemReducers from "./positionInstallModemReducers"
import modemModelsReducers from "./modemModelsReducers"
import citiesReducers from "./citiesReducers"
import pointSalesReducers from "./pointSalesReducers"
import addressesReducers from "./addressesReducers"
import vendorsReducers from "./vendorsReducers"
import statisticsReducers from "./statisticsReducers"
import navbarReducers from "./navbarReducers"
import segmentsReducers from "./segmentsReducers"

export default combineReducers({
  routing: routerReducer,
  toastr: toastrReducer,
  sidebar,
  auth,
  modems: modemsReducers,
  companies: companiesReducers,
  users: usersReducers,
  roles: rolesReducers,
  advertisingServices: advertisingServicesReducers,
  modemInstallPositions: positionInstallModemReducers,
  modemModels: modemModelsReducers,
  cities: citiesReducers,
  pointSales: pointSalesReducers,
  addresses: addressesReducers,
  vendors: vendorsReducers,
  statistics: statisticsReducers,
  navbar: navbarReducers,
  segments: segmentsReducers,
})
