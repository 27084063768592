import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import { findItemBy } from 'helpers';
// import { toastr } from 'react-redux-toastr';

import { Badge, Collapse } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import routes from '../routes';
import wifiIcon from '../assets/img/wifi.svg';

const SidebarCategory = withRouter(
	({ name, badgeColor, badgeText, icon, /*icon: Icon,*/ isOpen, children, handleClick, location, to }) => {
		const getSidebarItemClass = path => {
			return location.pathname.indexOf(path) !== -1 || (location.pathname === '/' && path === '/dashboard')
				? 'active'
				: '';
		};

		return (
			<li className={`sidebar-item ${getSidebarItemClass(to)}`}>
				<span
					data-toggle="collapse"
					className={`sidebar-link ${!isOpen ? 'collapsed' : ''}`}
					onClick={handleClick}
					aria-expanded={isOpen ? 'true' : 'false'}
				>	
					{icon ? <i className={'icomoon lg align-middle mr-3 ' + icon} /> : null}
					{/*<Icon size={18} className="align-middle mr-3" />*/}
					<span className="align-middle">{name}</span>
					{badgeColor && badgeText ? (
						<Badge color={badgeColor} size={18} className="sidebar-badge">
							{badgeText}
						</Badge>
					) : null}
				</span>
				<Collapse isOpen={isOpen}>
					<ul id="item" className="sidebar-dropdown list-unstyled">
						{children}
					</ul>
				</Collapse>
			</li>
		);
	}
);

const SidebarItem = withRouter(({ name, badgeColor, badgeText, icon, location, to }) => {
	const getSidebarItemClass = path => {
		return location.pathname === path ? 'active' : '';
	};
	// console.log(to)
	return (
		<li className={`sidebar-item ${getSidebarItemClass(to)}`}>
			<NavLink to={to} className="sidebar-link" activeClassName="active">
				{icon ? <i className={'icomoon lg align-middle mr-3 ' + icon} /> : null}				
				<span className="align-middle">{name}</span>
				{badgeColor && badgeText ? (
					<Badge color={badgeColor} size={18} className="sidebar-badge">
						{badgeText}
					</Badge>
				) : null}
			</NavLink>
		</li>
	);
});

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		// this.sidebarRef = React.createRef();
		this.state = {
			filteredRoutes: []
		};
	};

	toggleSideBarCategory = index => {
		this.setState(state => ({
			[index]: !state[index]
		}));
	};

	filterRoutesByRules = (routes, auth) => {
		const { authUser } = auth.authData;
		let rules;
		let copyRoutes = Object.assign([], routes);
		let filteredRoutes = [];

		for (let category of copyRoutes) {
			let copyCategory = Object.assign({}, category);
			let filteredChildren = [];
			if (copyCategory.children && copyCategory.children.length) {
				for (let route of copyCategory.children) {
					let hasAccess = true;
					if (route.meta && route.meta.ruleType) {
						rules = findItemBy('ruleType', route.meta.ruleType, authUser.role.rules);
						// console.log(rules)
						hasAccess = rules && rules.view;
					}
					if (hasAccess) filteredChildren.push(route);
				}
				copyCategory.children = filteredChildren;
			}
			if (/*copyCategory.children.length*/true) {
				filteredRoutes.push(copyCategory)
			}

		}

		return filteredRoutes;		
	};

	setupRoutes = (routes, pathName) => {
		// console.log('setupRoutes: ', routes)
		const filteredRoutes = this.filterRoutesByRules(routes, this.props.auth)
		
		filteredRoutes.forEach((route, index) => {
			const isActive = pathName.indexOf(route.path) === 0;
			const isOpen = route.open;
			const isHome = !!(route.containsHome && pathName === '/');

			this.setState( prevState => ({
				[index]: isActive || isOpen || isHome
			}));
		});

		this.setState({ filteredRoutes: filteredRoutes});
	};	

	componentWillMount() {
		/* Open collapse element that matches current url */
		const pathName = this.props.location.pathname;

		this.setupRoutes(routes, pathName)
		// console.log(this.state.filteredRoutes, routes)
		// console.log('componentWillMount: ', this.state.filteredRoutes)		
	}

	render() {
		const { sidebar /* layout */ } = this.props;
		// console.log('render: ', this.state.filteredRoutes, routes)
		
		return (
			<nav className={`sidebar${!sidebar.isOpen ? ' toggled' : ''}${sidebar.isSticky ? ' sidebar-sticky' : ''}`}>
				<div className="sidebar-content">
					<PerfectScrollbar>
						<a className="sidebar-brand" href="/">
							{/* <Box className="align-middle text-primary mr-2" size={24} />{" "} */}
							<img src={wifiIcon} alt="wifi" width="16" height="16" className="align-middle mr-2" />
							<span className="align-middle">
								<b>WI-Fi</b> Аналитика
							</span>
						</a>

						<ul className="sidebar-nav">
							{this.state.filteredRoutes.map((category, catIndex) => {
								return (
									<React.Fragment key={'category-'+catIndex}>
										{category.header ? <li className="sidebar-header">{category.header}</li> : null}

										{category.children && category.children.length ? (
											<SidebarCategory
												name={category.name}
												badgeColor={category.badgeColor}
												badgeText={category.badgeText}
												icon={category.icon}
												to={category.path}
												isOpen={this.state[catIndex]}
												handleClick={() => this.toggleSideBarCategory(catIndex)}
											>
												{category.children.map((route, routeIndex) => (
													<SidebarItem
														key={'route-'+routeIndex}
														name={route.name}
														to={route.path}
														icon={route.icon}
														badgeColor={route.badgeColor}
														badgeText={route.badgeText}
													/>
												))}
											</SidebarCategory>
										) : category.meta && category.meta.showWithoutChildren ? (

											<SidebarItem
												name={category.name}
												to={category.path}
												icon={category.icon}
												badgeColor={category.badgeColor}
												badgeText={category.badgeText}
											/>
										) : null}
									</React.Fragment>
								);
							})}
						</ul>
						{/*
            {!layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                  <img
                    className="rounded-circle mr-3"
                    src={avatar}
                    alt="Chris Wood"
                    width="40"
                    height="40"
                  />
                  <div className="media-body">
                    <h5 className="mb-1">Chris Wood</h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="text-success"
                      />{" "}
                      Online
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            */}
					</PerfectScrollbar>
				</div>
			</nav>
		);
	}
}

export default withRouter(
	connect(store => ({
		sidebar: store.sidebar,
		layout: store.layout,
		auth: store.auth
	}))(Sidebar)
);
