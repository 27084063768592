import { fetchItemsFor, saveItemFor, deleteItemFor } from './common/apiActions';
import { setItemsFor, setMetaFor, setFiltersFor, setSortingDataFor } from './common/itemsDataActions';

const fetchCompanies = payload => {
	return fetchItemsFor('COMPANIES_', '/companies')(payload);
};

const saveCompany = payload => {
	return saveItemFor('COMPANIES_', '/companies')(payload);
};

const deleteCompany = id => {
	return deleteItemFor('COMPANIES_', '/companies')(id);	
}

const setCompanies = companies => {
	return setItemsFor('COMPANIES_')(companies);
};

const setCompaniesFilters = filters => {
	return setFiltersFor('COMPANIES_')(filters);
};

const setCompaniesMeta = meta => {
	return setMetaFor('COMPANIES_')(meta);
};

const setCompaniesSorting = data => {
	return setSortingDataFor('COMPANIES_')(data);
};

export {
	fetchCompanies,
	saveCompany,
	deleteCompany,
	setCompanies,
	setCompaniesFilters,
	setCompaniesMeta,
	setCompaniesSorting
}
