import { combineReducers } from 'redux';
import itemsDataReducerFor from './common/itemsDataReducerFor';
// import filtersReducerFor from './common/filtersReducerFor';
import statusReducerFor from './common/statusReducerFor';

const positionInstallModemReducers = combineReducers({
  positionInstallModemData: itemsDataReducerFor('POSITION_INSTALL_MODEM_'),
  // positionInstallModemFilters: filtersReducerFor('POSITION_INSTALL_MODEM_'),  
  positionInstallModemStatus: statusReducerFor('POSITION_INSTALL_MODEM_'),
});

export default positionInstallModemReducers;
