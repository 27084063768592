import { combineReducers } from 'redux';
import itemsDataReducerFor from './common/itemsDataReducerFor';
// import filtersReducerFor from './common/filtersReducerFor';
import statusReducerFor from './common/statusReducerFor';

const pointSalesReducers = combineReducers({
  pointSalesData: itemsDataReducerFor('POINT_SALES_'),
  // pointSalesFilters: filtersReducerFor('POINT_SALES_'),  
  pointSalesStatus: statusReducerFor('POINT_SALES_'),
});

export default pointSalesReducers;
