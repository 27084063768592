import { combineReducers } from 'redux';
import itemsDataReducerFor from './common/itemsDataReducerFor';
import filtersReducerFor from './common/filtersReducerFor';
import statusReducerFor from './common/statusReducerFor';

const companiesReducers = combineReducers({
  // usersData: usersDataReducer,
  companiesData: itemsDataReducerFor('COMPANIES_'),
  companiesFilters: filtersReducerFor('COMPANIES_'),  
  companiesStatus: statusReducerFor('COMPANIES_'),
});

export default companiesReducers;
