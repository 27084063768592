import { combineReducers } from 'redux';
import itemsDataReducerFor from './common/itemsDataReducerFor';
// import filtersReducerFor from './common/filtersReducerFor';
import statusReducerFor from './common/statusReducerFor';

const vendorsReducers = combineReducers({
  vendorsData: itemsDataReducerFor('VENDORS_'),
  // vendorsFilters: filtersReducerFor('VENDORS_'),  
  vendorsStatus: statusReducerFor('VENDORS_'),
});

export default vendorsReducers;
