import { combineReducers } from 'redux';
import itemsDataReducerFor from './common/itemsDataReducerFor';
// import filtersReducerFor from './common/filtersReducerFor';
import statusReducerFor from './common/statusReducerFor';

const citiesReducers = combineReducers({
  citiesData: itemsDataReducerFor('CITIES_'),
  // citiesFilters: filtersReducerFor('CITIES_'),  
  citiesStatus: statusReducerFor('CITIES_'),
});

export default citiesReducers;
