import { useEffect, useState } from 'react';
// import isEmpty from 'lodash.isempty';

/* const isValidImage = (file, size) => {
	const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];

	let isFileImage = file && acceptedImageTypes.includes(file['type'])
	let acceptedImageSize = file && file.size < (size || 6291456) //2097152
	// console.log(isFileImage, acceptedImageSize)
	return !!isFileImage && !!acceptedImageSize	
} */

/* const isValidFile = (file, customSize) => {
	// const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
	// console.log(file)
	let size = customSize || 6291456;

	return file && file.size <= size 
	// let isFileImage = file && acceptedImageTypes.includes(file['type'])
} */
import { extendMoment } from 'moment-range';
import Moment from 'moment';
const moment = extendMoment(Moment);

const isImage = fileObj => {
	return /\.(gif|jpg|jpeg|tiff|png)$/gim.test(fileObj.path);
};

const findItemBy = (property, value, itemsList=[], returnIndex=false) => {
	let result = null; let index;
	if (itemsList.length) {
		for (let i = 0; i < itemsList.length; i++) {
			if (itemsList[i][property]) {
				if (itemsList[i][property] === value) {
					result = JSON.parse(JSON.stringify(itemsList[i]));
					index = i;
					break;
				}
			}
		}
	}
	return returnIndex ? {item: result, index: index} : result;
};

const getValues = (keyName, itemsList) => {
	let result = [];

	for (let item of itemsList) {
		if (item[keyName]) {
			result.push( item[keyName] );
		}
	}
	return result;
};

// -----------Dates-------------
const getRangeValue = dates => {
	let dateFrom = moment(dates.start).format('DD.MM.YY');
	let dateTo = moment(dates.end).format('DD.MM.YY');

	return `${dateFrom} - ${dateTo}`
};

const getDateValue = date => {
	return moment(date).format('YYYY-MM-DD');
};

// ---------Select--------
const getIdsForSelect = selectData => {
	let result;

	if (selectData instanceof Array) {
		result = []
		for (var i = 0; i < selectData.length; i++) {
			if (selectData[i]) {
				result.push( selectData[i].value );			
			}
		}
	} else {
		result = null;

		if (selectData) {
			result = selectData.value;			
		}
	}
	// console.log(selectData)
	return result;
};

const setupSelectOptions = (itemsList, labelName) => {
	// console.log(itemsList, labelName)
	let options = [];

	for (let i = 0; i < itemsList.length; i++) {
		let option = {
			value: itemsList[i].id,
			label: itemsList[i][labelName]
		}
		options.push(option);
	}
	return options;
};

const findSelectedOptions = (selectedOptions = [], fetchedOptions, notArray = false) => {
	let findedSelectedOptions = [];
	if (!selectedOptions) {	selectedOptions = [];	}
	if (selectedOptions && !(selectedOptions instanceof Array)) {
		selectedOptions = [selectedOptions];		
	}
	// console.log(selectedOptions)

	for (let i = 0; i < selectedOptions.length; i++) {
		let item = findItemBy('value', selectedOptions[i].value, fetchedOptions);
		if (item) {
			findedSelectedOptions.push(selectedOptions[i]);
		}					
	}

	if (notArray) {
		if (findedSelectedOptions && findedSelectedOptions.length) {
			findedSelectedOptions = findedSelectedOptions[0];
		} else {
			findedSelectedOptions = null;
		}
	}

	return findedSelectedOptions;
};

const getPercents = (total, value) => {
	let result = 0;
	
	if (total > 0 && value > 0) {
		result =  100 / (total / value);
	}
	
	return +result.toFixed(2);
};
const compareValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    try {
    	if (!a || !b) {return 0;}
	    if ( !a.hasOwnProperty(key) || !b.hasOwnProperty(key) ) {
	      // property doesn't exist on either object
	      return 0;
	    }

	    const varA = (typeof a[key] === 'string')
	      ? a[key].toUpperCase() : a[key];
	    const varB = (typeof b[key] === 'string')
	      ? b[key].toUpperCase() : b[key];

	    let comparison = 0;
	    if (varA > varB) {
	      comparison = 1;
	    } else if (varA < varB) {
	      comparison = -1;
	    }
	    return (
	      (order === 'desc') ? (comparison * -1) : comparison
	    );
    } catch (e) {console.log(e)}
  };
};

const removeDuplicates = (list, prop) => {
	var newArray = [];
	var lookupObject  = {};

	try {
		for(var i in list) {
			if (list[i]) lookupObject[list[i][prop]] = list[i];
		}

		for(i in lookupObject) {
			newArray.push(lookupObject[i]);
		}
	} catch (e) {console.log(e)}

	return newArray;
};

function getDOM() {
  // return { readyState: document.readyState };
  return document.readyState;
};

const useDOMState = () => {
  const [readyState, setReadyState] = useState(getDOM());

  function handleDOM() {
    // document.readyState is a read-only property AFAICT.
    // Therefore, `setReadyState` has no effect on
    // `document.readyState`
    setReadyState(getDOM());
  }

  useEffect(() => {
    document.addEventListener('DOMContentLoaded', handleDOM);
    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOM);
    };
  }, []);

  return readyState;
};

let timer = null;
const onTableScroll = (stateCallback) => {
  return (e) => {
  	// console.log(e, stateCallback)
		if (timer) { clearTimeout(timer); }
		timer = setTimeout(function() {
			timer = null;
	    const element = e.target;
	    // console.log(element.firstElementChild.offsetWidth)
	    let scrollPercentage = 100 * element.scrollLeft / (element.scrollWidth-element.clientWidth); 
	    if (scrollPercentage > 98) {
	    	stateCallback(true);
	    } else {
	    	stateCallback(false);
	    }
	    // console.log(scrollPercentage)
	  }, 50);
  }
};

const getYmdDateString = ms => {
	const date = new Date(ms)
	let y = date.getFullYear();
	let m = date.getMonth() + 1;
	let d = date.getDate();

	m = m < 10 ? `0${m}` : m;
	d = d < 10 ? `0${d}` : d;
	return `${y}-${m}-${d}`;
};


/* const getIds = (itemsList, options) => {
	let ids = [];
	
	if (options && options.string) {
		for (let i = 0; i < itemsList.length; i++) {
			itemsList[i].id ? ids.push( itemsList[i].id + '') : null;
		}
		return ids;		
	}

	for (let i = 0; i < itemsList.length; i++) {
		itemsList[i].id ? ids.push( itemsList[i].id ) : null;
	}

	return ids;
} */

/* const bs64toFile = (url, filename, mimeType) => {
  return (fetch(url)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], filename, {type:mimeType});})
  )
} */

/* const hasRightsTo = (userRole, item) => {
	if (item.meta && item.meta.roles && item.meta.roles.length) {
		if (userRole) {
			return item.meta.roles.some((role) => {
				return userRole === role
			})          
		}
	}
	return true
} */

/* const defaultDateTimeFormat = (dateStr) => {
	if (dateStr) {
	  let arr = dateStr.split(' ');
	  let resultDate;

	  if (arr.length) {
	    let date = arr[0], time = arr[1];
	    let dateArr = date.split('-');
	    let resultDateArr = []

	    for (let i = dateArr.length - 1 ; i >= 0 ; i--) {
	      resultDateArr.push(dateArr[i])
	    }

	    if (resultDateArr.length) {
	      resultDate = resultDateArr.join('.')
	      resultDate += time ? ' '+time : '';
	      return resultDate
	    }
	  }
	  return dateStr
	}
	return 'date not set'
} */

export {
	isImage,
	findItemBy,
	getValues,
	getRangeValue,
	getDateValue,
	getIdsForSelect,
	setupSelectOptions,
	findSelectedOptions,
	getPercents,
	compareValues,
	removeDuplicates,
	useDOMState,
	onTableScroll,
	getYmdDateString
};
