import { combineReducers } from 'redux';
import itemsDataReducerFor from './common/itemsDataReducerFor';
// import filtersReducerFor from './common/filtersReducerFor';
import statusReducerFor from './common/statusReducerFor';

const modemModelsReducers = combineReducers({
  modemModelsData: itemsDataReducerFor('MODEM_MODELS_'),
  // modemModelsFilters: filtersReducerFor('MODEM_MODELS_'),  
  modemModelsStatus: statusReducerFor('MODEM_MODELS_'),
});

export default modemModelsReducers;
