import { combineReducers } from 'redux';
import statisticsDataReducerFor from './statistics/statisticsDataReducerFor';
import statisticsFiltersReducerFor from './statistics/statisticsFiltersReducerFor';
import statusReducerFor from './common/statusReducerFor';

const statisticsReducers = combineReducers({
  dashboardStatisticsData: statisticsDataReducerFor('DASHBOARD_'),
  dashboardStatisticsFilters: statisticsFiltersReducerFor('DASHBOARD_'),  
  dashboardStatisticsStatus: statusReducerFor('DASHBOARD_STATISTICS_'),
  dataLoadStatisticsData: statisticsDataReducerFor('DATALOAD_'),
  dataLoadStatisticsFilters: statisticsFiltersReducerFor('DATALOAD_'),  
  dataLoadStatisticsStatus: statusReducerFor('DATALOAD_STATISTICS_')
});

export default statisticsReducers;
