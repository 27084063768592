import { combineReducers } from 'redux';
import itemsDataReducerFor from './common/itemsDataReducerFor';
import filtersReducerFor from './common/filtersReducerFor';
import statusReducerFor from './common/statusReducerFor';

const segmentsReducers = combineReducers({
  segmentsData: itemsDataReducerFor('SEGMENTS_'),
  segmentsFilters: filtersReducerFor('SEGMENTS_'),  
  segmentsStatus: statusReducerFor('SEGMENTS_')
});

export default segmentsReducers;
