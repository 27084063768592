import { combineReducers } from 'redux';
import itemsDataReducerFor from './common/itemsDataReducerFor';
// import filtersReducerFor from './common/filtersReducerFor';
import statusReducerFor from './common/statusReducerFor';

const addressesReducers = combineReducers({
  addressesData: itemsDataReducerFor('ADDRESSES_'),
  // addressesFilters: filtersReducerFor('ADDRESSES_'),  
  addressesStatus: statusReducerFor('ADDRESSES_'),
});

export default addressesReducers;
